import {Component, Input} from '@angular/core';
import {finalize} from "rxjs";
import {ApiService} from "../xstudioz/services/api.service";

@Component({
  selector: 'app-send-otp',
  standalone: true,
  imports: [],
  template: `
    @if (waitingPeriod) {
      {{ waitingPeriod }} Seconds to resend
    } @else if (sendingOtp) {
      <span class="text-red-500">Sending otp...</span>
    } @else {
      <a class="text-blue-600" (click)="sendOtp()">Click here to send otp</a>
    }
  `,
  styles: ``
})
export class SendOtpComponent {
  @Input({required: true}) purpose;
  public sendingOtp = false;
  public waitingPeriod = 0;
  public waitingInterval;


  constructor(public api: ApiService) {
  }

  sendOtp() {
    this.sendingOtp = true;
    this.api.post('send-otp', {purpose: this.purpose})
      .pipe(finalize(() => this.sendingOtp = false))
      .subscribe((res: any) => {
        let timeout = 2 * 60 * 1000;
        setTimeout(() => {
          clearInterval(this.waitingInterval);
          this.waitingPeriod = 0;
        }, timeout)
        this.waitingPeriod = timeout / 1000;

        this.waitingInterval = setInterval(() => {
          this.waitingPeriod--;
        }, 1000)

      })
  }
}
